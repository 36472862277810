import React from "react";
import {loadStytch, LoginOrSignupView, StyleConfig, CallbackOptions, StytchFactory} from '@stytch/stytch-js'

export interface StytchProps {
  publicToken: string;
  loginOrSignupView?: LoginOrSignupView;
  style?: StyleConfig;
  callbacks?: CallbackOptions;
}

const useStytchFactory = (): StytchFactory => {
  const [stytchFactory, setStytchFactory] = React.useState<StytchFactory>();
  React.useEffect(() => {
    // If we pass the function in directly React will execute it as a SetStateAction
    // Need to wrap in an anon - see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
    loadStytch().then(globalStytch => setStytchFactory(() => globalStytch))
  }, []);

  return stytchFactory;
}

/**
 * Stytch JS React Component
 *
 * [Documentation](https://stytch.com/docs/javascript-sdk)
 */
export const Stytch = (props: StytchProps) => {
  const StytchFactory = useStytchFactory();

  const {publicToken, style, callbacks, loginOrSignupView} = props;

  React.useEffect(() => {
    if (!StytchFactory) {
      return;
    }
    const stytch = StytchFactory(publicToken, callbacks);

    stytch.mount({
      elementId: "#stytch-magic-link",
      style,
      loginOrSignupView,
    });
  }, [StytchFactory]);

  return <div id="stytch-magic-link"></div>;
};
